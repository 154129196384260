import { transformDate } from "pubtrack-frontend-library";

const inProcessEligibilitiesColumns = [
    {
        Header: 'Recipient',
        accessor: 'name',
        sortable: false
    },
    {
        Header: 'Since',
        accessor: 'days_past',
        sortable: false
    },
    {
        Header: 'Position',
        accessor: 'position',
        sortable: false
    },
]


const historicEligibilitiesColumns = [
    {
        Header: 'Institution / Funder',
        accessor: 'name',
        sortable: false
    },
    {
        Header: 'Request Count',
        accessor: 'requests',
        sortable: false
    },
]


const eligibilityDetailsColumns = [
    {
        Header: 'recipient',
        accessor: 'name',
        sortable: false
    },
    {
        Header: 'status',
        accessor: 'state',
        sortable: false
    },
    {
        Header: 'when',
        Cell: ({ row }) =>
            <span>{transformDate(row.original.dispatched) || "-"}</span>,
        sortable: false
    },
]

export { eligibilityDetailsColumns, historicEligibilitiesColumns, inProcessEligibilitiesColumns }