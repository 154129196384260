import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "pubtrack-frontend-library";


export async function getEligibilities() {
    return await axiosInstance
        .get(`/tracker/reports/statistics`)
        .catch(handleErrors)
};

export async function getEligibilityDetails(selectedMessage) {
    return await axiosInstance
        .get(`/tracker/reports/statistics/${selectedMessage}`)
        .catch(handleErrors)
};

export async function getEligibilityTermination(selectedMessage) {
    return await axiosInstance
        .get(`lsa/halt/${selectedMessage}`)
        .catch(handleErrors)
};
