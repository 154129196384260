import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "pubtrack-frontend-library";


export async function getWeeklyTransactions() {
    return await axiosInstance
        .get(`/tracker/reports/weekly/transactions`)
        .catch(handleErrors)
};

export async function getWeeklyArticles() {
    return await axiosInstance
        .get(`/tracker/reports/weekly/articles`)
        .catch(handleErrors)
};

export async function getLastYearArticles() {
    return await axiosInstance
        .get(`/tracker/reports/lastyear/articles`)
        .catch(handleErrors)
};
