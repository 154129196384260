import React from "react";
import Routes from "./routes";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyles, Footer } from "pubtrack-frontend-library";

const customTheme = {
  ...theme,

  primaryColor: '#1993d7',
  secondaryColor: '#4753a4',

  accordionHeaderBackgroundColor: '#1993d7',
  modalHeaderBackgroundColor: '#1993d7',
  tabsBackgroundColor: "#1993d7",
  tabActiveColor: "#fff",
  tabItemColor: "#000",

  navbarLogoBackgroundColor: '#373e44',
  navbarBackgroundColor: '#1993d7',
  navbarButtonHoverColor: '#4753a4',
  navbarButtonTextColor: '#fff',
  navbarButtonActiveTextColor: "#fff",
  navbarFontWeight: 500,
  navbarTextTransform: "initial",
  navbarButtonHoverTextColor: "#fff",

  logoutButtonBackgroundColor: '#fff',
  logoutButtonTextColor: "#1993d7",

  paginationButtonBackgroundColor: '#1993d7',
  paginationButtonHoverBackgroundColor: '#4753a4',

  tableHeaderBackgroundColor: '#1993d7',

  searchButtonBackgroundColor: '#4753a4',
  defaultButtonBackgroundColor: '#4753a4',
  primaryButtonBackgroundColor: '#4753a4',
  colorsPalette: [
    "#002242", "#144773", "#346ea4", "#4e8ab8", "#6faed2", "#9ecbe3"
  ]
}

const App = () => (
  <ThemeProvider theme={customTheme}>
    <GlobalStyles />
    <Routes />
    <Footer />
  </ThemeProvider>
);

export default App;