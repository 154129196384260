import axios from 'axios';
import { BASE_URL, OASB_URL } from '../constants';
import { token as TOKEN } from "pubtrack-frontend-library";

const axiosInstance = axios.create({
    baseURL: BASE_URL
});

const axiosOasbInstance = axios.create({
    baseURL: `${OASB_URL}`
});

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem(TOKEN);
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Content-Type"] = 'application/json';
    return config;
});

axiosOasbInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem(TOKEN);
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Content-Type"] = 'application/json';
    return config;
});


export { axiosInstance, axiosOasbInstance };